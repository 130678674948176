html,
body {
  background-color: #ffffff;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background-color: transparent;
  text-align: unset;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

* {
  box-sizing: border-box;
}

.sb-show-main.sb-main-padded {
  padding: 0;
}
